import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import SEO from "../components/seo"


const ConditionPage = () => {
  const data = useStaticQuery(graphql`
    query ConditionPageQuery {
      allFile(filter: {sourceInstanceName: {eq: "markdown"}, name: {regex: "/condition/"}}) {
        edges {
          node {
            name
            childMdx {
              body
            }
          }
        }
      }
    }
  `)

  let contents = data.allFile.edges
  
  return (
    <MDXProvider>
      <SEO title="condition" />
      <div className="conditionPage">
        <div className="conditionPage__text">
            {contents.map(content => (
              <>
                <MDXRenderer>{content.node.childMdx.body}</MDXRenderer>
              </>
            ))}

        </div>
      </div>
    </MDXProvider>
    
  )
}

export default ConditionPage